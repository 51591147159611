import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'om-input',
  templateUrl: './om-input.component.html',
  styleUrls: ['./om-input.component.scss']
})
export class OmInputComponent implements OnInit {

  @Input() control: FormControl;  
  @Input() placeholder = '';

  @Input() label: string;
  @Input() icon: string;
  @Input() options: any[];
  @Input() showError = true;
  @Input() type = 'text';

  isRequired$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl('');
    this.isRequired$.next(this.control.hasValidator(Validators.required))
  }
}
