<om-label *ngIf="label" [text]="label" [icon]="icon" [isRequired]="isRequired$ | async"></om-label>
<nz-textarea-count *ngIf="textLength" [nzMaxCharacterCount]="textLength">
  <textarea [disabled]="disabled" [formControl]="control" nz-input [placeholder]="placeholder" [cols]="cols"
    [rows]="rows" [maxlength]="textLength" [ngClass]="{'textarea-no-resize': resize === false}">
  </textarea>
</nz-textarea-count>
<textarea [disabled]="disabled" [formControl]="control" nz-input [placeholder]="placeholder" [cols]="cols"
[rows]="rows" [maxlength]="textLength" [ngClass]="{'textarea-no-resize': resize === false}">
</textarea>
<om-error [control]="control"></om-error>
