import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { isObservable, Observable, of } from 'rxjs';

@Component({
  selector: 'om-select-formly',
  templateUrl: './om-select-formly.component.html',
  styleUrls: ['./om-select-formly.component.scss'],
})
export class OmSelectFormlyComponent extends FieldType implements OnInit {

  options$: Observable<any[]>;

  ngOnInit(): void {
    if (isObservable(this.props.options)) {
      this.options$ = this.props.options;  
    } else {
      this.options$ = of(this.props.options);
    }
    if (this.props.required) {
      this.formControl.addValidators(Validators.required);
    }
  }

}
