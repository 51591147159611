import { Injectable } from "@angular/core";
import { IDataSource } from "./datasource.abstract";
import { Observable, of } from "rxjs";
import { UserQuery } from "../state/user/user.query";
import { catchError, map, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { IQuery } from "@common/interfaces/query";
import { ConfiguratorHttpService } from "../service/configurator/communication/configurator-http.service";

@Injectable({
  providedIn: 'any',
})
export class QueryDataSource implements IDataSource {
  public readonly name = 'QUERY';
  constructor(
    protected configHttp: ConfiguratorHttpService<IQuery>,
    private http: HttpClient
  ) { }
  getOptions$(queryOptions: { queryId: string, optionValue?: string, optionLabel: string }): Observable<{ value: string, label: string, source: any }[]> {
    const { queryId, optionLabel, optionValue } = queryOptions
    if (!queryId || !optionLabel || !optionValue) {
      return of([]);
    }
    const options = this.http.get<IQuery>('/api/query/' + queryId).pipe(
      catchError(err => ([])),
      switchMap((async res => {
        const query = res;
        if (!query) {
          return [];
        }
        const rv = await this.configHttp.rawQuery(query, {});

        if (!rv.items) {
          return [];
        }
        return rv.items.map(item => ({ value: item[optionValue], label: item[optionLabel], source: item }));
      }))
    );
    return options;
  }
  getOptionsForQuerySelect(): Observable<{ value: string, label: string }[]> {
    return this.http.get<IQuery[]>('/api/query').pipe(
      catchError(err => ([])),
      map((queries => {
        if (!queries) {
          return [];
        }
        return queries.sort((a, b) => a.name.localeCompare(b.name)).map(q => ({ value: q.id, label: q.name }));
      }))
    );
  }
}
