import { Injectable, Injector } from '@angular/core';
import { IProject } from '@common/interfaces/project';
import { Topics } from '@common/interfaces/topics';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ActionService } from '../action/action.service';
import { CrudService } from '../crudService';
import { FilterService } from '../filter/filter.service';
import { interfaceDataServiceMap } from '../interfaceDataMapping';
import { IssueService } from '../issue/issue.service';
import { LastUpdateService } from '../lastUpdate/lastUpdate.service';
import { NotificationsService } from '../notifications/notifications.service';
import { BaseEntityStoreStrategy } from '../updateStoreStrategies';
import { ProjectQuery } from './project.query';
import { ProjectStore } from './project.store';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends CrudService<IProject,ProjectStore,ProjectQuery> {

  constructor(
    public store: ProjectStore,
    private filterService: FilterService,
    public query: ProjectQuery,
    protected actionService: ActionService,
    protected updateStrategy: BaseEntityStoreStrategy,
    private lastUpdateService: LastUpdateService,
    private injector: Injector,
    private notifsService: NotificationsService,
  ) {
    super(
      Topics.PROJECTS,
      store,
      query,
      actionService,
      updateStrategy
    );
  }

  
  setActive(id: string) {  // id può essere null (vedi setActive(null) alla riga sottostante)
    this.resetBadges(id);
    this.store.setActive(id);
    this.store.ui.setActive(id);
    this.filterService.initializeFilter(id);
    this.notifsService.removeNotifsByProject(id);
  }

  reloadProject(projectId: string) {    
    this.lastUpdateService.cleanChildLastUpdate(projectId);
    for (const serviceClass of Object.values(interfaceDataServiceMap)) {
      const service = this.injector.get(serviceClass);
      service.removeByProject(projectId);
    }
    const issueService = this.injector.get(IssueService);
    issueService.removeByProject(projectId);
    if (this.query.getActiveId() !== projectId) {
      return;
    }
    this.setActive(null);

    this.setActive(projectId);
  }

  getActive$(): Observable<IProject> {
    return this.query.active$;
  }

  getActive(): IProject {
    return this.query.getActive();
  }

  addBadges(projectId: string, news: string[]): void {
    const active = this.getActive();
    if(!active || active.id !== projectId) {
      this.store.ui.update(projectId as string, ui => { 
        const newsBadge = new Set<string>([...news, ...ui.newsBadge]);
        return {...ui, newsBadge: [...newsBadge.values()]} 
      });
    }
  }

  resetBadges(projectId: string): void { 
    if(!projectId) return;
    this.store.ui.update(projectId as string, ui => { return {...ui, newsBadge: []} });
  }

  getBadgeNews(projectId: string): number {
    return this.query.ui.getEntity(projectId as string).newsBadge.length;
  }

  getBadgeNews$(projectId: string): Observable<number> {
    return this.query.ui.selectEntity(projectId as string).pipe(
      filter(projectUi => !!projectUi),
      map(projectUi => {
        const active = this.getActive();
        if(!active || projectUi.id !== active.id) {
          return projectUi.newsBadge.length;
        } else {
          return 0;
        }
      }),
      distinctUntilChanged(),
    );
  }

  updateProject(id: string, updates: Partial<IProject>) {
    const prevProject = this.query.getEntity(id);
    let payload = {...updates, id} as Partial<IProject>;
    this.store.upsert(id, payload);
    this.actionService.queueAction({ entity: this.store.storeName, topic: this.topic, type: "update", payload, backup: prevProject })
  }

  setCurrentProjectItemView(item: string, view: string) {
    this.store.ui.updateActive(state => ({
      ...state,
      itemView: {...state.itemView || {}, [item]: view,}
    }));
  }

  setCurrentProjectItem(item: string) {
    this.store.ui.updateActive(state => ({ ...state, item: item }));
  }

}
