import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'om-checkbox',
  templateUrl: './om-checkbox.component.html',
  styleUrls: ['./om-checkbox.component.scss']
})
export class OmCheckboxComponent implements OnInit {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() value?: boolean = true;
  @Input() icon: string;
  @Input() disabled = false;

  isRequired$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl(false);
  }
}
