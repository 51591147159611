import { Injectable } from "@angular/core";
import { IBaseEntity } from "@common/interfaces/base";
import { BehaviorSubject, Subject } from "rxjs";

export enum Action { Remove, Reset, Save }
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  action$: Subject<Action> = new Subject();
  submitForm$: Subject<boolean> = new Subject();
  searchListElement$: Subject<string> = new Subject();
  clickListItem$: BehaviorSubject<any> = new BehaviorSubject(null);
  elementListener$: BehaviorSubject<IBaseEntity> = new BehaviorSubject(null);
  currentActiveForm$: BehaviorSubject<any> = new BehaviorSubject(null);
  deletable$ = new BehaviorSubject(true);
  actionInProgress$ = new BehaviorSubject(false);

}
