import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

const icons = ["None", "Task", "Bug", "Story", "CallCenter", "Delivery", "Bag", "Gisfo", "Factory", "Building", "HeadQuarter", "Deployment", "Control", "Shop"]

@Component({
  selector: 'formly-icon-select',
  templateUrl: './formly-icon-select.component.html',
  styleUrls: ['./formly-icon-select.component.scss']
})
export class FormlyIconSelectComponent extends FieldType implements OnInit {
  ngOnInit(): void {
    this.props.options = icons.map(i => { return { value: i.toLowerCase(), label: i } })
  }
}
