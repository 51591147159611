import { Component, OnInit } from '@angular/core';
import { EmailValidator, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { FormlyValidators } from '../validators/formly.validators';

@Component({
  selector: 'om-input-formly',
  templateUrl: './om-input-formly.component.html',
  styleUrls: ['./om-input-formly.component.scss'],
})
export class OmInputFormlyComponent extends FieldType implements OnInit {

  ngOnInit(): void {
    if (this.props.required) {
      this.formControl.addValidators(Validators.required);
    }
  }

}
