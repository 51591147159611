import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IDomain } from "@common/interfaces/domain";
import { IIssueState } from "@common/interfaces/issueState";
import { IIssueType } from "@common/interfaces/issueType";
import { IGroup, IRole } from "@common/interfaces/permissions";
import { IBaseEntityWithName } from "@ep-om/project/components/configuration/abstractDetail/abstractDetail.component";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ConfigurationService } from "../configuration.service";
import { IOLOConfig } from "@common/interfaces/oloConfig";
import { IExecutionQueryResult, IQuery } from "@common/interfaces/query";
import { Observable, of } from "rxjs";
import { IProject } from "@common/interfaces/project";
import { IExcelReport } from "@common/interfaces/excelReport";

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorHttpService<T extends IBaseEntityWithName>{

  constructor(
    private http: HttpClient,
    private nzns: NzNotificationService,
    private configService: ConfigurationService
  ) {}

  async patchEntity(url: string, body: Partial<T>): Promise<T> {
    return await this.http.patch<Partial<T>>(`${url}/${body.id}`, body)
      .toPromise()
      .then(entity => {
        this.nzns.success(`L'entità '${entity.name}' è stata aggiornata.`, '');
        return entity;
      }).catch(err => {
        this.nzns.error("L'entità NON è stata salvata", err.message);
        return null;
      });
  }

  async postEntity(url: string, body: Partial<T>): Promise<T> {
    return await this.http.post<Partial<T>>(url, body)
      .toPromise()
      .then(entity => {
        this.nzns.success(`L'entità '${entity.name}' è stata salvata.`, '');
        return entity;
      }).catch(err => {
        this.nzns.error("L'entità NON è stata salvata.", err.message);
        return null;
      });
  }

  async removeEntity(url: string, listElement: { name: string, id: string }) {
    return await this.http.delete(`${url}/${listElement.id}`)
      .toPromise()
      .then(removedId => {
        this.nzns.success(`L'entità '${listElement.name}' è stata rimossa.`, '');
        this.configService.clickListItem$.next(null);
        this.configService.currentActiveForm$.next(null);
        return removedId;
      }).catch(err => {
        this.nzns.error("L'entità NON è stata rimossa.", err.message);
        return null;
      });
  }

  async getStatesAndTaskTypes(url: string) {
    return await this.http.get<{ issueStates: IIssueState[], issueTypes: IIssueType[] }>(`${url}/statesAndTypes`)
      .toPromise()
      .catch(err => {
        this.nzns.error('Can not get states and types.', err.message());
        return null;
      });
  }

  async getEntityById(url: string) {
    return await this.http.get<T>(`${url}`).toPromise()
  }

  async getAllEntities(url: string) {
    return await this.http.get<{ id: string, name: string }[]>(`${url}`).toPromise()
  }

  async getIssueStateByName(name: string) {
    return await this.http.get<{ id: string, name: string }[]>(`/api/issueState/getByName?name=${name}`).toPromise();
  }

  getAllAvailableDomains(companyId: string) {
    return this.http.get<IDomain[]>(`/api/domain/getAllAvailables?companyId=${companyId}`);
  }

  getOloConfigs() {
    return this.http.get<IOLOConfig[]>(`/api/OLOConfig`);
  }


  async getAllGlobalRoles() {
    return await this.http.get<IRole[]>(`/api/permissions/globalRoles`).toPromise();
  }

  async rawQuery(query:Partial<IQuery>, params:any):Promise<IExecutionQueryResult> {
    return await this.http.post<IExecutionQueryResult>(`/api/query/exec`,{ query, params }).toPromise();
  }

  allQuery$():Observable<IQuery[]> {
    return this.http.get<IQuery[]>(`/api/query/?fields=id,name`);
  }

  dataSources$():Observable<string[]> {
    return this.http.get<string[]>(`/api/query/data-sources`);
  }

  allGroups$():Observable<IGroup[]> {
    return this.http.get<IGroup[]>(`/api/permissions/getGroups`);
  }

  someQuery$(ids:string[]):Observable<IQuery[]> {
    return this.http.get<IQuery[]>(`/api/query/some?ids=${ids.join(",")}`);
  }

  allProjects$():Observable<IProject[]> {
    return this.http.get<IProject[]>(`/api/project/?fields=id,name`);
  }

  templatesOfExport$(id:string):Observable<string[]> {
    return this.http.get<string[]>(`/api/exports/templates/${id}`);
  }

  isQueryUsed$(id:string):Observable<IExcelReport[]> {
    return this.http.get<IExcelReport[]>(`/api/exports/using-query/${id}`);
  }
}
