<div class="flex" [ngClass]="formControl.errors && formControl.dirty ? 'mb-0' : 'mb-6'">
  <om-input-number
    *ngIf="props.type === 'number'; else elseblock"
    [control]="formControl"
    [placeholder]="props.placeholder"
    [label]="props.label"
    [step]="props.step || 1"
    [max]="props.max"
    [min]="props.min">
  </om-input-number>
  <ng-template #elseblock>
    <om-input class="w-full"
      [control]="formControl"
      [placeholder]="props.placeholder"
      [label]="props.label"
      [type]="props.type"
    ></om-input>
  </ng-template>
</div>
