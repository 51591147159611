import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { sha1 } from 'object-hash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'om-select',
  templateUrl: './om-select.component.html',
  styleUrls: ['./om-select.component.scss']
})
export class OmSelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() disabled: boolean = false;
  @Input() mode: 'multiple' | 'tags' | 'default' = 'default';
  @Input() clear: boolean = false;
  
  @Input() placeholder: string;
  @Input() label: string;
  @Input() icon: string;

  @Input() options: any[];
  @Input() valueName: string = 'value';
  @Input() labelName: string = 'label';

  isRequired$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl(this.mode === 'default' ? '' : []);
    this.isRequired$.next(this.control.hasValidator(Validators.required));
  }
  compare(o1: any, o2: any) {
    if (typeof o1 !== typeof o2) {
      return false;
    }
    if (typeof o1 !== 'object') {
      return o1 === o2;
    }
    return sha1(o1) === sha1(o2);
  }
}
