<om-label *ngIf="label" [text]="label" [icon]="icon" [isRequired]="isRequired$ | async"></om-label>
<ng-container *ngIf="!options; else elseBlock">
  <!-- om-input normale-->
  <input class="w-full" nz-input [placeholder]="placeholder" [formControl]="control" [type]="type" />
</ng-container>

<ng-template #elseBlock>
  <!-- om-input con anche autocompletamento -->
  <input class="w-full" nz-input [placeholder]="placeholder" [formControl]="control" [type]="type" [nzAutocomplete]="auto" />
  <nz-autocomplete #auto>
    <nz-auto-option *ngFor="let item of options" [nzLabel]="item.value" [nzValue]="item.value">
      {{item.value}}
    </nz-auto-option>
  </nz-autocomplete>
</ng-template>

<om-error *ngIf="showError" [control]="control"></om-error>
