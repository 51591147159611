import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'om-textarea',
  templateUrl: './om-textarea.component.html',
  styleUrls: ['./om-textarea.component.scss']
})
export class OmTextareaComponent implements OnInit {

  @Input() control: FormControl;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '...';

  @Input() label: string;
  @Input() icon: string;

  @Input() textLength: number;
  @Input() cols: number = 60;
  @Input() rows: number = 4;
  @Input() resize: boolean = false;

  isRequired$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl('');
    this.isRequired$.next(this.control.hasValidator(Validators.required))
  }
  
}
