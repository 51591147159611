<div class="flex" [ngClass]="formControl.errors && formControl.dirty ? 'mb-0' : 'mb-6'">
  <om-textarea class="w-full"
    [control]="formControl"
    [placeholder]="props.placeholder"
    [label]="props.label"
    [disabled]="props.disabled"
    [icon]="props.icon"
    [textLength]="props.textLength"
    [cols]="props.cols"
    [rows]="props.rows"
    [resize]="props.resize"
  ></om-textarea>
</div>
