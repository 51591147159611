import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'om-input-number',
  templateUrl: './om-input-number.component.html',
  styleUrls: ['./om-input-number.component.scss']
})
export class OmInputNumberComponent implements OnInit, OnChanges {

  @Input() control: FormControl;
  @Input() placeholder = '';

  @Input() label: string;
  @Input() icon: string;
  @Input() min: number = Number.MIN_VALUE;
  @Input() max: number = Number.MAX_VALUE;
  @Input() step: number = 1;

  isRequired$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit(): void {
    this.control = this.control ?? new FormControl('');
    this.isRequired$.next(this.control.hasValidator(Validators.required))
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe( val => {
      if (val === '') { 
        this.control.setValue(null);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }
}
